











































import { Vue, Watch, Component } from 'vue-class-decorator'
import { UserModule, GlobalModule } from '@/store'
import { Payload } from 'vue/interfaces'

@Component({
  metaInfo: {
    title: 'Авторизация'
  }
})
export default class Login extends Vue {
  public login: null | string
  public password: string
  public showPassword: boolean
  public errorMessages: string

  // Data
  constructor() {
    super()
    this.login = ''
    this.password = ''
    this.showPassword = false
    this.errorMessages = ''
  }

  // Store init
  @UserModule.Action('login') userLogin!: (payload: object) => Promise<void>
  @GlobalModule.Getter('getLoading') loading!: boolean

  @Watch('name')
  claerErrorMessages() {
    this.errorMessages = ''
  }

  authProcess() {
    const payload = {
      login: this.login,
      password: this.password
    } as Payload
    this.userLogin(payload)
      .then(() => {
        this.$noty('success', 'Добро пожаловать!', 7000).show()
        setTimeout(() => {
          if (this.$route.query.redirect && typeof this.$route.query.redirect === 'string') {
            this.$router.replace(this.$route.query.redirect)
          } else {
            this.$router.push('/')
          }
        }, 1500)
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }
}
